<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="vehicleData === undefined">
      <h4 class="alert-heading">Errore dati Veicolo</h4>
      <div class="alert-body">
        Nessun Veicolo trovato con questo ID. Controlla la
        <b-link class="alert-link" :to="{ name: 'apps-vehiclesreception-list' }"> Lista dei Veicoli </b-link>
        per altri.
      </div>
    </b-alert>

    <template v-if="vehicleData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <vehicles-view-vehicle-info-card :vehicle-data="vehicleData" />
        </b-col>
        <b-col cols="12">
          <events-list />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue'
import vehiclesStoreModule from '../vehiclesStoreModule'
import VehiclesViewVehicleInfoCard from './VehiclesViewVehicleInfoCard.vue'
import EventsList from '@/views/apps/events/events-list/EventsList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    EventsList,
    VehiclesViewVehicleInfoCard,
  },
  setup() {
    const toast = useToast()
    const vehicleData = ref(null)

    const VEHICLES_APP_STORE_MODULE_NAME = 'app-vehicles'

    // Register module
    if (!store.hasModule(VEHICLES_APP_STORE_MODULE_NAME)) {
      store.registerModule(VEHICLES_APP_STORE_MODULE_NAME, vehiclesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLES_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(VEHICLES_APP_STORE_MODULE_NAME)
      }
    })

    store
      .dispatch('app-vehicles/fetchVehicle', { id: router.currentRoute.params.id })
      .then((response) => {
        vehicleData.value = response.data
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      vehicleData,
    }
  },
}
</script>

<style></style>
