<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-row>
      <b-col
        class="mb-4 d-flex justify-content-center align-items-center"
        cols="12"
      >
        <feather-icon
          icon="TruckIcon"
          size="25"
          class="mr-2"
        />
        <h1>Informazioni Veicolo</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="vehicleData.image_path"
        class="mb-2"
        cols="12"
      >
        <b-img
          :src="mediaUrl + vehicleData.image_path"
          alt="Immagine Veicolo"
          class="vehicle_img"
        />
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          ID Veicolo:
        </p>
        <h3 class="mb-1">
          #{{ vehicleData.id }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Nome Veicolo:
        </p>
        <h3 class="mb-1">
          {{ vehicleData.display_name }}
        </h3>
      </b-col>
      <b-col
        v-if="vehicleData.factory_year"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Anno Immatricolazione:
        </p>
        <h3 class="mb-1">
          {{ vehicleData.factory_year }}
        </h3>
      </b-col>
      <b-col
        v-if="vehicleData.type"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Tipologia:
        </p>
        <h3 class="mb-1">
          {{ vehicleData.type }}
        </h3>
      </b-col>
      <b-col
        v-if="vehicleData.model"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Modello:
        </p>
        <h3 class="mb-1">
          {{ vehicleData.model }}
        </h3>
      </b-col>
      <b-col
        v-if="vehicleData.seat_number"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          N° Posti:
        </p>
        <h3 class="mb-1">
          {{ vehicleData.seat_number }}
        </h3>
      </b-col>
      <b-col
        v-if="vehicleData.fuel_type"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Alimentazione:
        </p>
        <h3 class="mb-1 text-capitalize">
          {{ vehicleFuelTypes[vehicleData.fuel_type] }}
        </h3>
      </b-col>
      <b-col
        v-if="vehicleData.availability_status"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Disponibilità:
        </p>
        <h3 class="mb-1">
          <feather-icon
            icon="CircleIcon"
            size="15"
            :class="[vehicleAvailabilitiesColor[vehicleData.availability_status], 'mr-1']"
          />
          {{ vehicleAvailabilities[vehicleData.availability_status] }}
        </h3>
      </b-col>
      <b-col
        v-if="vehicleData.note"
        class="mb-2"
        cols="12"
      >
        <p class="mb-1">
          Note:
        </p>
        <h4 class="mb-1">
          {{ vehicleData.note }}
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="vehicleData.features && vehicleData.features.length > 0"
        class="mb-2"
        cols="12"
      >
        <p class="mb-1">
          Caratteristiche:
        </p>
        <b-badge
          v-for="feature in vehicleData.features"
          :key="feature"
          class="mr-2"
          variant="primary"
        >
          #{{ feature }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="text-right mt-2"
        cols="12"
      >
        <b-button
          :to="{ name: 'apps-vehiclesreception-edit', params: { id: vehicleData.id } }"
          variant="primary"
        >
          Modifica
        </b-button>
        <!-- <b-button
          @click="
            $bvModal.show('delete-modal')
            selectedVehicle = vehicleData.id
          "
          variant="outline-danger"
          class="ml-1"
        >
          Elimina
        </b-button> -->
      </b-col>
    </b-row>
    <b-modal
      id="delete-modal"
      title="Cancella Veicolo"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Cancella Veicolo
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler cancellare definitivamente questo Veicolo e i suoi dati correlati?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            deleteVehicle(selectedVehicle)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BBadge, BLink, BModal, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BBadge,
    BLink,
    BModal,
    BImg,
  },
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vehicleAvailabilitiesColor: {
        available: 'text-success',
        unavailable: 'text-secondary',
        BUSY: 'text-warning',
      },
      vehicleAvailabilities: {
        available: 'Disponibile',
        unavailable: 'Non Disponibile',
        BUSY: 'Occupato',
      },
      vehicleFuelTypes: {
        diesel: 'Diesel',
        gasoline: 'Benzina',
        methanol: 'Metano',
        gpl: 'GPL',
        electric: 'Elettrica',
        hydrogen: 'Idrogeno',
      },
      selectedVehicle: null,
    }
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    deleteVehicle(id) {
      store
        .dispatch('app-vehicles/deleteVehicle', { id })
        .then(() => {
          this.selectedVehicle = null
          this.$router.replace({ name: 'apps-vehiclesreception-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Veicolo #${id} eliminato con successo`,
                icon: 'TruckIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
.vehicle_img {
  width: 100%;
  max-height: 500px;
  object-position: center;
  object-fit: cover;
  border-radius: 5px;
}
</style>
